import React from "react";
import { mapping, DropDownListButton, CSAStyle } from "@aim-mf/styleguide";

class ControlDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    return (
      <div style={CSAStyle.ControlDetailWrapperStyle}>
        <div
          style={{
            ...mapping["heading/h5/lightleft"],
            padding: "1rem 0 0 1rem",
          }}
        >
          Control Details
        </div>
        <div style={CSAStyle.ControlDetailDropDownsWrapperStyle}>
          <div style={CSAStyle.DropDownContentStyle}>
            <div style={CSAStyle.sectionTitleStyle}>Control Type</div>
            <DropDownListButton
              value={this.props.controlDetail.CSA_type}
              data={this.props.controlDetailDropdownlistItem.type}
              CustomOnChange={(event) => {
                this.props.handleControlDetailChange("CSA_type", event.value);
              }}
              DropDownButtonStyle={CSAStyle.riskTierDropDownButtonStyle}
            />
          </div>
          <div style={CSAStyle.DropDownContentStyle}>
            <div style={CSAStyle.sectionTitleStyle}>Antifraud</div>
            <DropDownListButton
              value={this.props.controlDetail.antifraud}
              data={this.props.controlDetailDropdownlistItem.antifraud}
              CustomOnChange={(event) => {
                this.props.handleControlDetailChange("antifraud", event.value);
              }}
              DropDownButtonStyle={CSAStyle.riskTierDropDownButtonStyle}
            />
          </div>
          <div style={CSAStyle.DropDownContentStyle}>
            <div style={CSAStyle.sectionTitleStyle}>Manual/System control</div>
            <DropDownListButton
              value={this.props.controlDetail.manual_system}
              data={this.props.controlDetailDropdownlistItem.manual_system}
              CustomOnChange={(event) => {
                this.props.handleControlDetailChange(
                  "manual_system",
                  event.value
                );
              }}
              DropDownButtonStyle={CSAStyle.riskTierDropDownButtonStyle}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { ControlDetail };
