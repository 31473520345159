import React, { useCallback } from "react";
import {
  fetchAPI,
  fetchUserAPIWithPermission,
  fetchAPIWithPermission,
  fetchAdminAPIWithPermission,
} from "@aim-mf/api";
import { ControlSelfAssessment } from "./page/controlSelfAssessment";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";
import "./app.scss";

const initialState = {
  loadingControl: true,
  loadingControlCSA: true,
  loadingUser: true,
  loadingRisk: true,
  risk: {},
  userList: [],
  control: {},
  controlCSA: {},
};

function App(props) {
  // this only works if no hash!
  let controlID = document.location.pathname.split("/");
  controlID = controlID[controlID.length - 2];

  const [state, dispatch] = React.useReducer(reducer, initialState);

  // TODO: testing state need to remove later
  const {
    loadingControl,
    loadingControlCSA,
    loadingUser,
    loadingRisk,
    risk,
    control,
    controlCSA,
    userList,
  } = state;

  const navigateToOtherControl = (id) => {
    controlID = id;
    dispatch({ type: "loadingControlCSA" });
    dispatch({ type: "loadingControl" });
    dispatch({ type: "loadingRisk" });
  };

  React.useEffect(() => {
    if (loadingUser) {
      dispatch({ type: "loadingUser" });
      const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadUser", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadingUserFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingControl) {
      dispatch({ type: "loadingControl" });
      const subscription = fetchAPIWithPermission(
        "getriskcontrol/" + controlID
      ).subscribe(
        (results) => {
          dispatch({ type: "getControl", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadingControlFunction();
          });
        }
      );
      return () => subscription.unsubscribe();
    }
    if (loadingControlCSA) {
      dispatch({ type: "loadingControlCSA" });
      const controlCSASubscription = fetchAPIWithPermission(
        "controlselfassessment/" + controlID
      ).subscribe(
        (results) => {
          dispatch({ type: "getControlCSA", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadingControlCSAFunction();
          });
        }
      );

      return () => controlCSASubscription.unsubscribe();
    }
    if (loadingRisk) {
      dispatch({ type: "loadingRisk" });
      const subscription = fetchAPIWithPermission(
        "getriskbycontrolid/" + controlID
      ).subscribe(
        (results) => {
          dispatch({ type: "getRisk", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadingRiskFunction();
          });
        }
      );
      return () => subscription.unsubscribe();
    }
  }, [
    loadingUser,
    loadingControl,
    loadingControlCSA,
    loadingRisk,
    controlID,
    loadingUserFunction,
    loadingControlFunction,
    loadingControlCSAFunction,
    loadingRiskFunction,
  ]);

  const loadingUserFunction = useCallback(() => {
    const userSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadUser", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadingUserFunction();
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const loadingControlFunction = useCallback(() => {
    const subscription = fetchAPIWithPermission(
      "getriskcontrol/" + controlID
    ).subscribe(
      (results) => {
        dispatch({ type: "getControl", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadingControlFunction();
        });
      }
    );
    return () => subscription.unsubscribe();
  });
  const loadingControlCSAFunction = useCallback(() => {
    const controlCSASubscription = fetchAPIWithPermission(
      "controlselfassessment/" + controlID
    ).subscribe(
      (results) => {
        dispatch({ type: "getControlCSA", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadingControlCSAFunction();
        });
      }
    );

    return () => controlCSASubscription.unsubscribe();
  });
  const loadingRiskFunction = useCallback(() => {
    const subscription = fetchAPIWithPermission(
      "getriskbycontrolid/" + controlID
    ).subscribe(
      (results) => {
        dispatch({ type: "getRisk", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadingRiskFunction();
        });
      }
    );
    return () => subscription.unsubscribe();
  });

  const extractContentType = (response) => {
    //console.log(response);
    return response.headers["content-type"];
  };

  const handleAttachmentRemove = (ids) => {
    //console.log(ids);
    const data = new FormData();
    ids.forEach((id) => {
      data.append("file_ids", id);
    });
    const controlCSAFileUploadSubscription = fetchAPIWithPermission(
      "controlselfassessment/deleteattachment/" + controlID,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("delete finished");
        dispatch({ type: "loadingControlCSA" });
      },
      (err) => {
          console.log("err", err) // eslint-disable-line
      }
    );
    return () => controlCSAFileUploadSubscription.unsubscribe();
  };

  const handleAttachmentUpload = (files) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file);
    });
    const controlCSAFileUploadSubscription = fetchAPIWithPermission(
      "controlselfassessment/addattachment/" + controlID,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("upload finished");
        dispatch({ type: "loadingControlCSA" });
      },
      (err) => {
          console.log("err", err) // eslint-disable-line
      }
    );
    return () => controlCSAFileUploadSubscription.unsubscribe();
  };

  const handleAttachmentDownload = (id, attachmentFilename) => {
    const controlCSASubscription = fetchAPIWithPermission(
      "controlselfassessment/attachment/" + id,
      { responseType: "arraybuffer" }
    ).subscribe(
      (response) => {
        let blob = new Blob([response.data], {
          type: extractContentType(response),
        });
        let downloadUrl = window.URL.createObjectURL(blob);
        let filename = attachmentFilename;
        //console.log(blob);

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      },
      (err) => {
          console.log("err", err) // eslint-disable-line
      }
    );
    return () => controlCSASubscription.unsubscribe();
  };

  const handleUpdateCSASubmit = React.useCallback((data) => {
    // eslint-disable-next-line no-console
    console.log(["controlselfassessment/update/" + controlID, data]);
    const subscription = fetchAPIWithPermission(
      "controlselfassessment/update/" + controlID,
      data
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingControlCSA" });
        handleUpdateWithCSADetail(controlID);
        //props.setFocusedRiskID(results.data.id);
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );

    return () => subscription.unsubscribe();
  });

  const handleUpdateWithCSADetail = (controlID) => {
    const subscription = fetchAPIWithPermission(
      "controlselfassessment/" + controlID
    ).subscribe(
      (results) => {
        dispatch({ type: "getControlCSA", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => subscription.unsubscribe();
  };

  return (
    <div>
      <ControlSelfAssessment
        loading={
          loadingUser || loadingRisk || loadingControl || loadingControlCSA
        }
        risk={risk}
        userList={userList}
        control={control}
        controlCSA={controlCSA}
        handleAttachmentDownload={handleAttachmentDownload}
        handleUpdateCSASubmit={handleUpdateCSASubmit}
        navigateToOtherControl={navigateToOtherControl}
        handleAttachmentUpload={handleAttachmentUpload}
        handleAttachmentRemove={handleAttachmentRemove}
      />
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loadingUser":
        // console.log("in loading riskList")
        return { ...state, loadingUser: true };
      case "loadingControl":
        // console.log("in loading riskList")
        return { ...state, loadingControl: true };
      case "loadingRisk":
        // console.log("in loading riskList")
        return { ...state, loadingRisk: true };
      case "loadingControlCSA":
        // console.log("in loading riskList")
        return { ...state, loadingControlCSA: true };
      case "loadUser":
        return {
          ...state,
          userList: action.results.data,
          loadingUser: false,
        };
      case "getControl":
        return {
          ...state,
          control: action.results.data,
          loadingControl: false,
        };
      case "getRisk":
        return {
          ...state,
          risk: action.results.data,
          loadingRisk: false,
        };
      case "getControlCSA":
        return {
          ...state,
          controlCSA: action.results.data,
          loadingControlCSA: false,
        };
      case "fetchMore":
        return {
          ...state,
          loadingRisk: true,
          pageNum: state.pageNum + 1,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
