import React from "react";
import { IconSVG, CSAStyle } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    if (!this.props.risk.name) {
      return <div />;
    }
    return (
      <div name={"SideBar"} style={CSAStyle.SideBarStyle}>
        <div name={"sidebar-title"} style={CSAStyle.titleCategoryFont}>
          {this.props.risk.category.toUpperCase()}
        </div>
        <ul name={"sidebar-menu"}>
          <li
            className={"div_hover"}
            style={
              this.props.risk.id === this.state.hoverID
                ? CSAStyle.MenuWrapperHover
                : CSAStyle.MenuWrapper
            }
            onMouseEnter={() => {
              this.setState({ hoverID: this.props.risk.id });
            }}
            onMouseLeave={() => {
              this.setState({ hoverID: -1 });
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              style={CSAStyle.parentMenuFont}
              onClick={() => {
                navigateToUrl("/risk-edit/" + this.props.risk.id + "/");
              }}
            >
              <IconSVG name={"work"} color={"white"} size={"0.6"} />
              {this.props.risk.name}
            </div>
          </li>
          {this.props.risk.controls
            .filter((control) => {
              return control.control_type === "Manual";
            })
            .map((control) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  className={"div_hover"}
                  key={control.id}
                  style={
                    control.id === this.props.control.id ||
                    control.id === this.state.hoverID
                      ? CSAStyle.MenuWrapperHover
                      : CSAStyle.MenuWrapper
                  }
                  onMouseEnter={() => {
                    this.setState({ hoverID: control.id });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hoverID: -1 });
                  }}
                  onClick={() => {
                    navigateToUrl(
                      "/control-self-assessment/" + control.id + "/"
                    );
                    this.props.navigateToOtherControl(control.id);
                  }}
                >
                  {this.props.control.id === control.id && (
                    <div style={CSAStyle.indicatorBar} />
                  )}
                  <div style={CSAStyle.subMenuFont}>
                    &#11172;&nbsp;&nbsp;&nbsp;{control.description}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export { SideBar };
