import React from "react";
import { mapping, CSAStyle } from "@aim-mf/styleguide";
import { ControlSelfAssessmentDocumentAssessment } from "./CSADocumentAssessment";
import { ControlSelfAssessmentDocumentRemediationPlans } from "./CSADocumentRemediationPlans";
import { ControlSelfAssessmentDocumentTestResults } from "./CSADocumentTestResults";

class ControlSelfAssessmentDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={CSAStyle.ControlDocumentWrapperStyle}>
        <div
          style={{
            ...mapping["heading/h5/lightleft"],
            padding: "1rem 0 0 1rem",
          }}
        >
          Control Self Assessment Documentation
        </div>

        {this.props.documents.map((document, index) => {
          return (
            <div key={this.props.documents.id}>
              <ControlSelfAssessmentDocumentAssessment
                document={document}
                documentIndex={index}
                handleDocumentsChange={this.handleDocumentsChange}
                handleAddUser={this.props.handleAddUser}
              />
              <ControlSelfAssessmentDocumentTestResults
                documentIndex={index}
                handleRemarkOpen={this.props.handleRemarkOpen}
                testResult={document.test_result}
                handleDocumentTestResultChange={
                  this.handleDocumentTestResultChange
                }
              />
              <ControlSelfAssessmentDocumentRemediationPlans
                documentIndex={index}
                remediationPlans={document.remediation_plan}
                handleDocumentRemediationPlanChange={
                  this.handleDocumentRemediationPlanChange
                }
                handleAddUser={this.props.handleAddUser}
              />
            </div>
          );
        })}
      </div>
    );
  }

  handleDocumentsChange = (index, key, value) => {
    let newDocument = this.props.documents;
    newDocument[index][key] = value;
    this.setState({ documents: newDocument }, () => {
      this.props.handleDocumentChange(this.props.documents);
    });
  };

  handleDocumentTestResultChange = (index, testResult) => {
    let newDocument = this.props.documents;
    newDocument[index].test_result = testResult;
    this.props.handleDocumentChange(newDocument);
  };

  handleDocumentRemediationPlanChange = (index, remediationPlans) => {
    let newDocument = this.props.documents;
    newDocument[index].remediation_plan = remediationPlans;
    this.props.handleDocumentChange(newDocument);
  };
}

export { ControlSelfAssessmentDocument };
