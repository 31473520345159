import React from "react";
import {
  IconSVG,
  mapping,
  ButtonSolid,
  NameCard,
  AutoSizeTextArea,
  CustomDatePicker,
  IconButtonSolid,
  CSAStyle,
} from "@aim-mf/styleguide";

class ControlSelfAssessmentDocumentRemediationPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remediationPlans: this.props.remediationPlans,
    };
  }

  render() {
    return (
      <div style={CSAStyle.ControlDocumentRPDropDownsWrapperStyle}>
        <div style={{ ...CSAStyle.sectionTitleStyle, marginLeft: "1.25rem" }}>
          Remediation Plan
        </div>
        {this.props.remediationPlans.map((remediationPlan, index) => {
          return (
            <RemediationPlanCard
              documentIndex={this.props.documentIndex}
              remediationPlan={remediationPlan}
              index={index}
              handleRemediationPlanChangeChange={
                this.handleRemediationPlanChangeChange
              }
              handleAddUser={this.props.handleAddUser}
              handleRPDelete={this.handleRPDelete}
            />
          );
        })}
        <div
          style={{
            height: "1.25rem",
            marginLeft: "1.25rem",
            marginRight: "1.3rem",
            backgroundColor: mapping["Color_BG_02_solid"],
          }}
        />
        <AddNew
          handleRemediationPlanAddNew={this.handleRemediationPlanAddNew}
        />
      </div>
    );
  }

  handleRemediationPlanAddNew = () => {
    let newRemediationPlan = this.state.remediationPlans;
    newRemediationPlan.push({
      description: "",
      target_date: new Date(),
      owner: [],
      is_new: true,
    });
    this.setState({ testResult: newRemediationPlan }, () => {
      this.props.handleDocumentRemediationPlanChange(
        this.props.documentIndex,
        this.state.remediationPlans
      );
    });
  };

  handleRemediationPlanChangeChange = (index, remediationPlans) => {
    let newRemediationPlans = this.state.remediationPlans;
    newRemediationPlans[index] = remediationPlans;
    this.setState({ remediationPlans: newRemediationPlans }, () => {
      this.props.handleDocumentRemediationPlanChange(
        this.props.documentIndex,
        this.state.remediationPlans
      );
    });
  };
  handleRPDelete = (index) => {
    let newRemediationPlans = this.state.remediationPlans;
    newRemediationPlans.splice(index, 1);
    this.props.handleDocumentRemediationPlanChange(
      this.props.documentIndex,
      newRemediationPlans
    );
  };
}

const RemediationPlanCard = (props) => {
  const onRemediationPlanChange = (key, value) => {
    let remediationPlan = props.remediationPlan;
    remediationPlan[key] = value;
    props.handleRemediationPlanChangeChange(props.index, remediationPlan);
  };
  return (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        marginLeft: "1.25rem",
        backgroundColor: mapping["Color_BG_02_solid"],
        paddingTop: "1.25rem",
        paddingLeft: "1.25rem",
      }}
    >
      <div
        className={"div_hover"}
        style={{ position: "absolute", top: "1.5625rem", right: "0.125rem" }}
      >
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleRPDelete(props.index);
          }}
        />
      </div>
      <div>
        <PlanDescription
          description={props.remediationPlan.description}
          onRemediationPlanChange={onRemediationPlanChange}
        />
      </div>
      <div>
        <PlanTimeline
          value={props.remediationPlan.target_date}
          onRemediationPlanChange={onRemediationPlanChange}
        />
      </div>
      <div>
        <PlanOwner
          owner={props.remediationPlan.owner}
          index={props.index}
          documentIndex={props.documentIndex}
          handleAddUser={props.handleAddUser}
        />
      </div>
    </div>
  );
};

const PlanDescription = (props) => {
  const onCustomChange = (value) => {
    props.onRemediationPlanChange("description", value);
  };
  return (
    <div>
      <div style={{ ...CSAStyle.sectionTitleStyle, width: "33.1875rem" }}>
        Description
      </div>
      <ContentTextInput
        width={"31.5rem"}
        value={props.description}
        onCustomChange={onCustomChange}
      />
    </div>
  );
};

const PlanTimeline = (props) => {
  return (
    <div>
      <div style={{ ...CSAStyle.sectionTitleStyle, width: "10rem" }}>
        Target Date
      </div>
      <div style={{ width: "10rem" }}>
        <TargetDate
          value={props.value}
          onRemediationPlanChange={props.onRemediationPlanChange}
        />
      </div>
    </div>
  );
};

const PlanOwner = (props) => {
  const OwnerChange = () => {
    props.handleAddUser(
      "RemediationPlanOwner",
      [props.documentIndex, props.index],
      props.owner,
      "Add Responsibility Owner"
    );
  };
  return (
    <div style={{ marginLeft: "1.72rem" }}>
      <div style={{ ...CSAStyle.sectionTitleStyle, width: "16.875rem" }}>
        Responsibility Owner
      </div>
      {props.owner.map((owner) => {
        return (
          <NameCard
            initial={owner.display_name
              .split(" ")
              .map((a) => a[0])
              .join("")}
            name={owner.display_name}
            title={owner.title}
          />
        );
      })}
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={CSAStyle.addButtonHeight}
        width={CSAStyle.addButtonWidth}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};

const ContentTextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.id);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{ ...CSAStyle.RPInputFieldStyle, width: props.width }}
      value={props.value}
      CustomizedOnChange={onChange}
    />
  );
};

const TargetDate = (props) => {
  const CustomOnChange = (value) => {
    props.onRemediationPlanChange("target_date", value);
  };
  return (
    <CustomDatePicker
      CustomOnChange={CustomOnChange}
      value={props.value} //{}FormatDate(props.value, "DD/MM/YYYY")}
    />
  );
};

const AddNew = (props) => {
  const onClick = () => {
    props.handleRemediationPlanAddNew();
  };
  return (
    <div style={CSAStyle.addNewButtonWrapper}>
      <div style={{ margin: "auto" }}>
        <IconButtonSolid
          name={"Add"}
          icon={
            <IconSVG
              name={"newRisk"}
              color={"white"}
              size={0.6}
              wrapperStyle={{
                transformOrigin: "0 50% 0",
              }}
            />
          }
          width={CSAStyle.addNewButtonWidth}
          height={CSAStyle.addNewButtonHeight}
          iconPos={"Left"}
          size={"Small"}
          clickEvent={onClick}
        />
      </div>
    </div>
  );
};

export { ControlSelfAssessmentDocumentRemediationPlans };
