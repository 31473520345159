import React from "react";
import {
  IconSVG,
  mapping,
  NonValidationInput,
  CustomDatePicker,
  CustomizedCheckBox,
  IconButtonSolid,
  CSAStyle,
} from "@aim-mf/styleguide";

class ControlSelfAssessmentDocumentTestResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={CSAStyle.ControlDocumentTestResultDropDownsWrapperStyle}>
        <div style={{ display: "inline-flex" }}>
          <TestTableHeader />
        </div>
        <div>
          {this.props.testResult
            .sort((a, b) => {
              return a.id > b.id ? 1 : 0;
            })
            .map((testResult, index) => {
              return (
                <TestTableContent
                  testResult={testResult}
                  index={index}
                  handleTestResultChange={this.handleTestResultChange}
                  handleTestResultDelete={this.handleTestResultDelete}
                  handleRemarkOpen={this.handleRemarkOpen}
                />
              );
            })}
          <div
            style={{
              height: "1.25rem",
              marginLeft: "1.25rem",
              marginRight: "1.3rem",
              backgroundColor: mapping["Color_BG_02_solid"],
            }}
          />
          <AddNew handleTestResultAddNew={this.handleTestResultAddNew} />
        </div>
      </div>
    );
  }

  handleRemarkOpen = (index) => {
    this.props.handleRemarkOpen(this.props.documentIndex, index);
  };

  handleTestResultAddNew = () => {
    let newTestResult = this.props.testResult;
    newTestResult.push({
      access_change_request: false,
      access_rights_review: false,
      date: new Date(),
      description: "",
      periodic_log_review: false,
      remarks: "",
      is_new: true,
    });
    this.props.handleDocumentTestResultChange(
      this.props.documentIndex,
      newTestResult
    );
  };

  handleTestResultChange = (index, key, value) => {
    let newTestResult = this.props.testResult;
    newTestResult[index][key] = value;
    this.props.handleDocumentTestResultChange(
      this.props.documentIndex,
      newTestResult
    );
  };

  handleTestResultDelete = (index) => {
    let newTestResult = this.props.testResult;
    newTestResult.splice(index, 1);
    this.props.handleDocumentTestResultChange(
      this.props.documentIndex,
      newTestResult
    );
  };
}

const TestTableHeader = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "flex-end",
        paddingLeft: "1.875rem",
      }}
    >
      <div style={{ ...CSAStyle.sectionTitleStyleWrap, width: "3.5625rem" }}>
        S/N
      </div>
      <div
        style={{
          ...CSAStyle.sectionTitleStyleWrap,
          width: "10rem",
          marginRight: "0.4rem",
        }}
      >
        Date of sample document
      </div>
      <div
        style={{
          ...CSAStyle.sectionTitleStyleWrap,
          width: "14.375rem",
          paddingRight: "2.5rem",
        }}
      >
        document description and reference
      </div>
      <div style={{ ...CSAStyle.sectionTitleStyleWrap, width: "9rem" }}>
        evidence of access rights review
      </div>
      <div style={{ ...CSAStyle.sectionTitleStyleWrap, width: "9rem" }}>
        Approved user access change request
      </div>
      <div style={{ ...CSAStyle.sectionTitleStyleWrap, width: "9.625rem" }}>
        evidence of periodic log review
      </div>
      <div style={{ ...CSAStyle.sectionTitleStyleWrap, width: "5.3125rem" }}>
        Remarks
      </div>
    </div>
  );
};

const TestTableContent = (props) => {
  return (
    <div style={CSAStyle.testResultContentWrapperStyle}>
      <div
        className={"div_hover"}
        style={{ position: "absolute", top: "1.5625rem", right: "0.125rem" }}
      >
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleTestResultDelete(props.index);
          }}
          size={1}
        />
      </div>
      <div style={{ ...CSAStyle.TestResultContentStyle, width: "2.9375rem" }}>
        {props.index + 1}
      </div>
      <div
        style={{
          ...CSAStyle.TestResultContentStyle,
          width: "10rem",
          marginRight: "0.5rem",
        }}
      >
        <DateOfSampleDocument
          index={props.index}
          handleTestResultChange={props.handleTestResultChange}
          value={props.testResult.date}
        />
      </div>
      <div style={{ ...CSAStyle.TestResultContentStyle, width: "14.3rem" }}>
        <DocumentDescription
          index={props.index}
          description={props.testResult.description}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div style={{ ...CSAStyle.TestResultContentStyle, width: "9rem" }}>
        <AccessRightsReview
          index={props.index}
          value={props.testResult.access_rights_review}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div style={{ ...CSAStyle.TestResultContentStyle, width: "9rem" }}>
        <AccessChangeRequest
          index={props.index}
          value={props.testResult.access_change_request}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div style={{ ...CSAStyle.TestResultContentStyle, width: "9rem" }}>
        <PeriodicLogReview
          index={props.index}
          value={props.testResult.periodic_log_review}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div className={"div_hover"} style={CSAStyle.remarkLinkWrapper}>
        <Remarks
          index={props.index}
          handleRemarkOpen={props.handleRemarkOpen}
        />
      </div>
    </div>
  );
};

const DateOfSampleDocument = (props) => {
  const CustomOnChange = (value) => {
    props.handleTestResultChange(props.index, "date", value);
  };
  return (
    <CustomDatePicker
      index={props.index}
      CustomOnChange={CustomOnChange}
      value={props.value}
    />
  );
};

const DocumentDescription = (props) => {
  const CustomOnChange = (event) => {
    props.handleTestResultChange(props.index, "description", event.value);
  };
  return (
    <TextInput
      width={"13.125rem"}
      onCustomChange={CustomOnChange}
      value={props.description}
      index={props.index}
    />
  );
};

const AccessRightsReview = (props) => {
  const onAccessRightsReviewToggle = () => {
    props.handleTestResultChange(
      props.index,
      "access_rights_review",
      !props.value
    );
  };
  return (
    <CustomizedCheckBox
      value={props.value}
      checkBoxStyle={{
        backgroundColor: props.value ? "#00BEFF" : "rgba(255,255,255,0.11)",
      }}
      onCheckChange={onAccessRightsReviewToggle}
    />
  );
};

const AccessChangeRequest = (props) => {
  const onAccessRightsReviewToggle = () => {
    props.handleTestResultChange(
      props.index,
      "access_change_request",
      !props.value
    );
  };
  return (
    <CustomizedCheckBox
      value={props.value}
      checkBoxStyle={{
        backgroundColor: props.value ? "#00BEFF" : "rgba(255,255,255,0.11)",
      }}
      onCheckChange={onAccessRightsReviewToggle}
    />
  );
};

const PeriodicLogReview = (props) => {
  const onAccessRightsReviewToggle = () => {
    props.handleTestResultChange(
      props.index,
      "periodic_log_review",
      !props.value
    );
  };
  return (
    <CustomizedCheckBox
      value={props.value}
      checkBoxStyle={{
        backgroundColor: props.value ? "#00BEFF" : "rgba(255,255,255,0.11)",
      }}
      onCheckChange={onAccessRightsReviewToggle}
    />
  );
};

const Remarks = (props) => {
  const handleRemarkClick = () => {
    props.handleRemarkOpen(props.index);
  };
  return (
    <IconSVG
      name={"comment"}
      color={mapping["Color_Basic_Primary"]}
      onClick={handleRemarkClick}
      size={1}
    />
  );
};

const AddNew = (props) => {
  const onClick = () => {
    props.handleTestResultAddNew();
  };
  return (
    <div style={CSAStyle.addNewButtonWrapper}>
      <div style={{ margin: "auto" }}>
        <IconButtonSolid
          name={"Add"}
          icon={
            <IconSVG
              name={"newRisk"}
              color={"white"}
              size={0.6}
              wrapperStyle={{
                transformOrigin: "0 50% 0",
              }}
            />
          }
          width={CSAStyle.addNewButtonWidth}
          height={CSAStyle.addNewButtonHeight}
          iconPos={"Left"}
          size={"Small"}
          clickEvent={onClick}
        />
      </div>
    </div>
  );
};

const TextInput = (props) => {
  return (
    <NonValidationInput
      fieldstyle={{
        ...CSAStyle.InputFieldStyle,
        width: props.width ? props.width : "210px",
      }}
      value={props.value}
      onChange={props.onCustomChange}
    />
  );
};

export { ControlSelfAssessmentDocumentTestResults };
