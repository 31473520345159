import React from "react";
import {
  IconSVG,
  mapping,
  NameCard,
  AutoSizeTextArea,
  CSAStyle,
} from "@aim-mf/styleguide";

class ControlBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    return (
      <div style={CSAStyle.BasicInfoStyle}>
        <div
          style={{
            ...mapping["heading/h3/lightleft"],
            paddingTop: "4rem",
            paddingBottom: "3rem",
          }}
        >
          {this.props.control.description}
        </div>
        <div style={{ display: "inline-flex" }}>
          <div>
            <ControlOwner owner={this.props.control.owner} />
            <div style={{ height: "3rem" }} />
            <ControlFrequency frequency={this.props.control.frequency} />
          </div>
          <div style={{ width: "8.5rem" }} />
          <div>
            <RefPolicy
              policy={this.props.refPolicy}
              handleRefPolicyChange={this.props.handleRefPolicyChange}
            />
            <div style={{ height: "1.6rem" }} />
            <Attachment
              attachments={this.props.attachment}
              uploadingFile={this.props.uploadingFile}
              handleAttachmentDownload={this.props.handleAttachmentDownload}
              handleAttachmentChange={this.handleAttachmentChange}
              handleRemoveUploadFile={this.props.handleRemoveUploadFile}
              handleRemoveFile={this.props.handleRemoveFile}
            />
          </div>
        </div>
      </div>
    );
  }
  handleAttachmentChange = (e) => {
    if (e.target.files.length) {
      this.props.handleUploadFile(e.target.files[0]);
    }
  };
}

const ControlOwner = (props) => {
  return (
    <div>
      <div style={CSAStyle.sectionTitleStyle}>Control Owner</div>
      {props.owner.map((owner) => {
        return (
          <NameCard
            initial={owner.display_name
              .split(" ")
              .map((a) => a[0])
              .join("")}
            name={owner.display_name}
            title={owner.title}
          />
        );
      })}
    </div>
  );
};

const ControlFrequency = (props) => {
  return (
    <div>
      <div style={CSAStyle.sectionTitleStyle}>Frequency</div>
      <div style={CSAStyle.ShortTextStyle}>{props.frequency}</div>
    </div>
  );
};

const RefPolicy = (props) => {
  return (
    <div>
      <div style={CSAStyle.sectionTitleStyle}>
        Reference to policies and procedures
      </div>
      <AutoSizeTextArea
        fieldstyle={CSAStyle.ShortMultilineInputFieldStyle}
        value={props.policy}
        CustomizedOnChange={props.handleRefPolicyChange}
      />
    </div>
  );
};

const Attachment = (props) => {
  return (
    <div>
      <div style={CSAStyle.sectionTitleStyle}>Attachments</div>
      {props.attachments.map((attachment, index) => {
        return (
          <div key={index}>
            <div style={CSAStyle.AttachmentWrapper}>
              <IconSVG name={"file"} color={mapping["Color_Basic_Link"]} />
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                style={CSAStyle.AttachmentStyle}
                onClick={() => {
                  props.handleAttachmentDownload(
                    attachment.id,
                    attachment.filename
                  );
                }}
              >
                {attachment.filename}
              </div>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={"div_hover"}
                style={CSAStyle.AttachmentDeleteButtonWrapperStyle}
                onClick={() => {
                  props.handleRemoveFile(attachment.id);
                }}
              >
                <IconSVG name={"delete"} size={0.8} color={"#eeeeee"} />
              </div>
            </div>
          </div>
        );
      })}
      {props.uploadingFile.map((attachment, index) => {
        return (
          <div key={index}>
            <div style={CSAStyle.AttachmentWrapper}>
              <IconSVG name={"file"} color={mapping["Color_Basic_Link"]} />
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div style={CSAStyle.AttachmentStyle}>{attachment.name}</div>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={"div_hover"}
                style={CSAStyle.AttachmentDeleteButtonWrapperStyle}
                onClick={() => {
                  props.handleRemoveUploadFile(index);
                }}
              >
                <IconSVG name={"delete"} size={"0.8"} color={"#eeeeee"} />
              </div>
            </div>
          </div>
        );
      })}

      <div style={CSAStyle.fileUploadWrapper}>
        <input
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: "none" }}
          onChange={props.handleAttachmentChange}
        />
        <label htmlFor="contained-button-file">
          <div style={CSAStyle.fakeButtonStyle}>Add +</div>
        </label>
      </div>
    </div>
  );
};

export { ControlBasicInfo };
