import React from "react";
import {
  mapping,
  ButtonSolid,
  AutoSizeTextArea,
  CSAStyle,
} from "@aim-mf/styleguide";

class Remarks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content,
    };
  }

  render() {
    return (
      <div style={CSAStyle.remarkFormWrapStyle}>
        <div style={CSAStyle.remarkFormStyle}>
          <div style={CSAStyle.contentWrapper}>
            <div
              style={{ ...mapping["forms/label/1-default"], color: "white" }}
            >
              Remarks
            </div>
            <AutoSizeTextArea
              fieldstyle={{ ...CSAStyle.MultilineInputFieldStyle }}
              value={this.state.content}
              CustomizedOnChange={(value) => {
                this.setState({ content: value });
              }}
            />
            <div style={CSAStyle.buttonWrapper}>
              <ButtonSolid
                name={"Cancel"}
                height={CSAStyle.remarkButtonHeight}
                width={CSAStyle.remarkButtonWidth}
                size={"Small"}
                clickEvent={this.props.handleRemarkClose}
              />
              <ButtonSolid
                name={"Save"}
                height={CSAStyle.remarkButtonHeight}
                width={CSAStyle.remarkButtonWidth}
                size={"Small"}
                clickEvent={() => {
                  this.props.handleRemarkSubmit(this.state.content);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Remarks };
